import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import Navigation from "../components/Navigation/Navigation";
import Main from "../components/Main/Main";
import Content from "../components/Content/Content";
import Advertisement from "../components/Advertisement/Advertisement"
import Title from "../components/Title/Title";
import Page from "../components/Page/Page";
import SkillCharts from "../components/SkillCharts/SkillCharts";
import Accordian from "../components/Accordian/Accordian";
import AccordianItem from "../components/Accordian/AccordianItem";
import Footer from "../components/Footer/Footer";

const ResumePage = ({ data }) => {
  let accordianBg = `bg-secondary-darkgrey`;
  let categories = [
    ...new Set(data.resume.edges.map(({ node }) => node.category))
  ];
  let posts = [...data.resume.edges];
  let title = `Resume`;

  return (
    <>
      <SEO title={title} />
      <Layout>
        <Navigation />
        <Main>
          <Content>
            <Title title={title} />
            <Page>
              <div className="d-flex flex-column flex-grow-1">
                <SkillCharts />
                <Accordian title={ title }>
                  { categories.map( ( category, i ) => (
                    <AccordianItem
                      key={ i }
                      index={ i }
                      title={ title }
                      category={ category }
                      accordianBg={ accordianBg }
                      posts={ posts.filter( ( { node } ) =>
                        node.category === category ? true : false
                      ) }
                    />
                  ) ) }
                </Accordian>
              </div>
            </Page>
          </Content>
          <Footer />
        </Main>
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    resume: allContentfulResumePost {
      edges {
        node {
          body {
            childMarkdownRemark {
              html
            }
          }
          link {
            text
            primaryColor
            textColor
            hoverColor
            href
          }
          startDate
          orderIndex
          secondaryText
          primaryText
          endDate
          date
          category
        }
      }
    }
  }
`;

export default ResumePage;
