import React from 'react';
import {
	CircularProgressbarWithChildren,
	buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Animation
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from '../AnimatedProgressProvider/AnimatedProgressProvider';

export default function SkillCharts() {
	const skills = [
		{
			language: `html`,
			percentage: 98
		},
		{
			language: `css`,
			percentage: 96
		},
		{
			language: `javascript`,
			percentage: 94
		}
	];
	return (
		<div
			id='SkillCharts'
			className='row d-flex justify-content-center align-items-center py-md-3 mb-3'
		>
			{skills.map((skill, i) => (
				<div
					key={i}
					id={`skillCharts-skill-${i}`}
                    className='col-md-3 d-flex m-md-3 justify-content-center align-items-center p-3 h-100'
                    data-class='skillCharts-skill'
				>
					<AnimatedProgressProvider
						valueStart={0}
						valueEnd={skill.percentage}
						duration={3 + i * 1.5}
						easingFunction={easeQuadInOut}
					>
						{value => (
							<CircularProgressbarWithChildren
								value={value}
								styles={buildStyles({
									pathTransition: 'none',
									strokeLinecap: 'butt',
									trailColor: '#333',
                                    pathTransitionDuration: 1
                                })}
                                className='d-flex justify-content-center align-items-center'
							>
								<p
                                    className='skillPercent w-100 text-center mb-2 mb-md-0 mb-lg-n1'
                                >{`${Math.round(value)}%`}</p>
								<p
									className='skillLang text-uppercase font-weight-bold w-100 text-center mb-0 mt-n2 mt-md-0'
								>{`${skill.language}`}</p>
							</CircularProgressbarWithChildren>
						)}
					</AnimatedProgressProvider>
				</div>
			))}
		</div>
	);
}
