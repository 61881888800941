import React from 'react'

export default function Accordian({title, children}) {
    return (
        <div className="row">
            <div
                className="accordion col-md-10 mx-auto"
                id={ `${ title }-accordian` }
            >
                { children }
            </div>
        </div>
    )
}
