import React from "react";
import ResumeAccordianPost from "./ResumeAccordianPost";

export default function AccordianItem({
  title,
  category,
  posts,
  accordianBg,
  index
}) {
  let accordianPosts = (() => {
    if (posts.map(post => (post.hasOwnProperty(`order`) ? true : false))) {
      return posts.sort(
        (postA, postB) => postA.node.orderIndex - postB.node.orderIndex
      );
    }
    return posts;
  })();

  return (
    <div className={`card ${accordianBg} mb-2 rounded border-0`}>
      <div className="card-header border-0" id="education">
        <h2 className="mb-0 d-flex justify-content-center d-md-block">
          <button
            className="btn btn-link collapsed text-light text-decoration-none stretched-link"
            type="button"
            data-toggle="collapse"
            data-target={`#collapse-${title}-${index}`}
            aria-expanded="false"
            aria-controls={`collapse-${title}-${index}`}
          >
            {category}
          </button>
        </h2>
      </div>
      <div
        id={`collapse-${title}-${index}`}
        className="collapse"
        aria-labelledby="education"
        data-parent={`#${title}-accordian`}
        style={{ zIndex: 1 }}
      >
        <div className="card-body bg-light border-0">
          <div className="container">
            {accordianPosts.map((post, i) => (
              <ResumeAccordianPost
                key={i}
                index={`${index}-${i}`}
                post={post}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
