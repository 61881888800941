import React from "react";
import ReactMarkdown from "react-markdown";

export default function ResumeAccordianPost({ post, index }) {
  let { node } = post
  let accordianPostLinkId = `${node.category}-accordian-${index}-link`;

  let body = node.body && node.body.childMarkdownRemark ? (<ReactMarkdown source={ node.body.childMarkdownRemark.html } escapeHtml={ false }/>) : null

  let backgroundColor = ( () => {
    if (node.link) {
      if (node.link.primaryColor) {
        return node.link.primaryColor
      }
    }
    return `inherit`
  })()

  let hoverColor = ( () => {
    if (node.link) {
      if (node.link.hoverColor) {
        return node.link.hoverColor
      }
    }
    return `inherit`
  })()

  let textColor = ( () => {
    if (node.link) {
      if (node.link.textColor) {
        return node.link.textColor
      }
    }
    return `inherit`
  })()

  return (
    <div className="row rounded bg-white text-black p-3 mb-3">
      <div className="col-12">
        <h5>
          <strong>{node.primaryText}</strong>
          {node.secondaryText ? <i>{`, ${node.secondaryText}`}</i> : ``}
        </h5>
      </div>
      {node.startDate || node.endDate || node.date ? (
        <div className="col-12">
          <h6>
            <i>
              {node.startDate || node.endDate
                ? node.startDate && node.endDate
                  ? `${node.startDate} - ${node.endDate}`
                  : node.startDate
                  ? node.startDate
                  : node.endDate
                : node.date}
            </i>
          </h6>
        </div>
      ) : (
        ``
      )}
      {body || node.link ? (
        <div className="col-12">
        {body && body}
        {node.link ? (
          <a
            id={accordianPostLinkId}
            href={node.link.href}
            className="btn btn-dark border-0 text-white"
            style={{
              zIndex: 2,
              backgroundColor: `${backgroundColor}`,
              color: `${textColor}`
            }}
            onMouseOver={() => {
              document.getElementById(accordianPostLinkId).style.backgroundColor = hoverColor 
            }}
            onMouseOut={() => {
              document.getElementById(accordianPostLinkId).style.backgroundColor = backgroundColor
            }}
            target="_blank"
          >
            {node.link.text}
          </a>
        ) : (
          ``
        )}
      </div>
      ) : null}
      
    </div>
  );
}
